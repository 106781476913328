import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { Box, UserMenu as UIKitUserMenu, UserMenuVariant, Disconnect, Skeleton } from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import { useAccount, useBalance } from 'wagmi'
import { useDomainNameForAddress } from 'hooks/useDomain'
import styled from 'styled-components'
// import ProfileUserMenuItem from './ProfileUserMenuItem'
// import Image from 'next/image'
// import Link from 'next/link'
import ButtonCopy from 'components/ButtonCopy'
// import NextLink from '@pancakeswap/uikit/src/components/NextLink/NextLink'
import { formatBigInt } from '@pancakeswap/utils/formatBalance'
import { useBSCCakeBalance } from 'hooks/useTokenBalance'
import { FetchStatus } from 'config/constants/types'
// import WalletModal, { WalletView } from './WalletModal'
// import WalletUserMenuItem from './WalletUserMenuItem'

const BlockAccountWrap = styled.div``
const BlockAccountInfo = styled.div`
  padding: 15px;
`

const BlockInfoAccount = styled.div``
const BlockInfoAccountWrap = styled.div``

const BlockAccount = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: 0.4s all;
`
const BlockIDWallet = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: SUISSE_BOLD;
  font-size: 1.6rem;
  line-height: calc(1.6rem * 1.25);
  color: #000;
  transition: 0.4s all;
`

const BlockWallet = styled.div`
  border: 1.5px solid rgba(240, 240, 240);
  border-left: none;
  border-right: none;
  margin: 5px 0px;
`
const BlockWalletItem = styled.div`
  padding: 10px 15px;
  border-bottom: 1.5px solid rgba(240, 240, 240);
  cursor: pointer;
  transition: 0.4s all;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
`
const BlockWrapInfo = styled.div``
const BlockWrapMoney = styled.div``
const TextName = styled.div`
  font-family: SUISSE_MEDIUM;
  margin: 0px 0px 5px 0px;
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.25);
  color: #000;
`
const TextMoney = styled.div`
  font-family: SUISSE_BOLD;
  font-size: 1.6rem;
  line-height: calc(1.6rem * 1.25);
  color: #000;
`

const BlockWrapInfoDirect = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const BlockListDirect = styled.div`
  margin: 15px 0px 5px 0px;
`

// const BlockDirectItem = styled(Link)`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   transition: 0.4s all;
//   height: 45px;
//   padding: 0px 15px;
//   border-radius: 10px;
//   cursor: pointer;
//   &:hover {
//     background: ${({ theme }) => theme.colors.background};
//     transition: 0.4s all;
//   }
// `
const BlockButtonItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.4s all;
  height: 45px;
  padding: 0px 15px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: rgba(240, 240, 240);
    transition: 0.4s all;
  }
`
const ImageDirect = styled.div`
  margin: 1.5px 15px 0px 0px;
  svg {
    width: 18px;
    height: 18px;
  }
`
const TextDirect = styled.div`
  font-family: SUISSE_BOLD;
  color: #000;
`
const BlockBtnCopy = styled.div`
  width: fit-content;
  // max-width: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-item: center;
  justify-content: flex-end;
`

const BtnConnect = styled(ConnectWalletButton)`
  color: #fff;
  @media screen and (max-width: 1199px) {
    border-radius: 8px;
    font-family: SUISEE_BOLD;
    height: 45px;
    padding: 0px 17.5px;
  }
`

const UserMenuItems = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { logout } = useAuth()
  const { isWrongNetwork } = useActiveChainId()

  // const { address: account } = useAccount()
  // const { hasPendingTransactions } = usePendingTransactions()
  // const { isInitialized, isLoading, profile } = useProfile()
  // const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  // const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  // const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  // const hasProfile = isInitialized && !!profile

  const bnbBalance = useBalance({ address: account, chainId: ChainId.BSC })
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useBSCCakeBalance()

  // const onClickWalletMenu = useCallback((): void => {
  //   if (isWrongNetwork) {
  //     onPresentWrongNetworkModal()
  //   } else {
  //     onPresentWalletModal()
  //   }
  // }, [isWrongNetwork, onPresentWalletModal, onPresentWrongNetworkModal])

  const textAddress = `${account.slice(0, 5)}••••${account.slice(-4)}`

  return (
    <>
      {/* <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
      <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
        {t('Recent Transactions')}
        {hasPendingTransactions && <RefreshIcon spin />}
      </UserMenuItem>
      <UserMenuDivider />
      <NextLink href={`/profile/${account?.toLowerCase()}`} passHref>
        <UserMenuItem disabled={isWrongNetwork || chainId !== ChainId.BSC}>{t('Your NFTs')}</UserMenuItem>
      </NextLink>
      <ProfileUserMenuItem
        isLoading={isLoading}
        hasProfile={hasProfile}
        disabled={isWrongNetwork || chainId !== ChainId.BSC}
      />
      <UserMenuDivider /> */}
      {/* <UserMenuItem as="button" onClick={logout}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {t('Disconnect')}
          <LogoutIcon />
        </Flex>
      </UserMenuItem> */}
      <BlockInfoAccount>
        <BlockInfoAccountWrap>
          <BlockAccountInfo>
            <BlockIDWallet>{!isWrongNetwork ? textAddress : 'Wrong network'}</BlockIDWallet>
            <BlockBtnCopy>
              <ButtonCopy textCopy={account} message="Copied" />
            </BlockBtnCopy>
          </BlockAccountInfo>
          <BlockWallet>
            <BlockWalletItem>
              <BlockWrapInfo>
                <TextName>BNB {t('Balance')}</TextName>
              </BlockWrapInfo>
              <BlockWrapMoney>
                {!bnbBalance.isFetched ? (
                  <Skeleton height="22px" width="60px" />
                ) : (
                  <TextMoney>{formatBigInt(bnbBalance?.data?.value ?? 0n, 6)}</TextMoney>
                )}
              </BlockWrapMoney>
            </BlockWalletItem>
            {/* <BlockWalletItem>
              <BlockWrapInfo>
                <TextName>BTCF Balance</TextName>
              </BlockWrapInfo>
              <BlockWrapMoney>
                {cakeFetchStatus !== FetchStatus.Fetched ? (
                  <Skeleton height="22px" width="60px" />
                ) : (
                  <TextMoney>{formatBigInt(cakeBalance, 3)}</TextMoney>
                )}
              </BlockWrapMoney>
            </BlockWalletItem> */}
          </BlockWallet>
          <BlockListDirect>
            <BlockButtonItem onClick={logout}>
              <BlockWrapInfoDirect>
                <ImageDirect>
                  <Disconnect />
                </ImageDirect>
                <TextDirect>Disconnect</TextDirect>
              </BlockWrapInfoDirect>
            </BlockButtonItem>
          </BlockListDirect>
        </BlockInfoAccountWrap>
      </BlockInfoAccount>
    </>
  )
}

const UserMenu = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { domainName, avatar } = useDomainNameForAddress(account)
  const { isWrongNetwork } = useActiveChainId()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const { profile } = useProfile()
  const avatarSrc = profile?.nft?.image?.thumbnail ?? avatar
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  if (account) {
    return (
      <UIKitUserMenu
        account={domainName || account}
        ellipsis={!domainName}
        avatarSrc={avatarSrc}
        text={userMenuText}
        variant={userMenuVariable}
      >
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <BlockAccount>
      <BlockAccountWrap>
        <BtnConnect>
          <Box display={['none', , , 'block']}>
            <Trans>Connect Wallet</Trans>
          </Box>
          <Box display={['block', , , 'none']}>
            <Trans>Connect</Trans>
          </Box>
        </BtnConnect>
      </BlockAccountWrap>
    </BlockAccount>
  )
}

export default UserMenu
