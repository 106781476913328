import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 512 512" {...props}>
      <path
        d="M392 405.099V451c0 8.399-6.599 15-15 15s-15-6.601-15-15v-45.901c4.799.601 9.901.901 15 .901s10.201-.3 15-.901z"
        fill="#FFF"
        opacity="1"
        data-original="#FFF"
        className=""
      />
      <path
        d="M487.7 193.9 389.299 52.301c-5.7-7.8-18.898-7.8-24.598 0L266.3 193.9C250.401 216.7 242 243.399 242 271c0 69.3 52.5 126.599 120 134.099v-67.888l-55.605-55.605c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0L362 294.789v-47.578l-25.605-25.605c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0l4.395 4.395V181c0-8.401 6.599-15 15-15s15 6.599 15 15v23.789l4.395-4.395c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352 0 21.211L392 247.211v47.578l34.395-34.395c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352 0 21.211L392 337.211v67.888c67.5-7.5 120-64.799 120-134.099 0-27.601-8.401-54.3-24.3-77.1zM167 466H45c-24.814 0-45-20.186-45-45v-30c0-24.814 20.186-45 45-45h122c24.814 0 45 20.186 45 45v30c0 24.814-20.186 45-45 45zM167 316H45c-24.814 0-45-20.186-45-45v-30c0-24.814 20.186-45 45-45h122c24.814 0 45 20.186 45 45v30c0 24.814-20.186 45-45 45zM167 166H45c-24.814 0-45-20.186-45-45V91c0-24.814 20.186-45 45-45h122c24.814 0 45 20.186 45 45v30c0 24.814-20.186 45-45 45z"
        fill="#FFF"
        opacity="1"
        data-original="#FFF"
        className=""
      />
    </Svg>
  );
};

export default Icon;
