import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
// import Image from "next/image";
import { Box, Flex } from "../../../../components/Box";
import { UserMenuProps } from "./types";
import { UserMenuItem } from "./styles";
// import { MetamaskIcon } from "../../../../components";

export const StyledUserMenu = styled(Flex)`
  width: fit-content;
  height: 45px;
  align-items: center;
  background: ${({ theme }) => theme.colors.gradientPrimary};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.4s all;
  position: relative;
  padding: 0px 10px;
  z-index: 990;
  color: #fff;
`;

export const LabelText = styled.div`
  color: ${({ theme }) => theme.colors.text};
  display: none;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    margin-left: 8px;
    margin-right: 4px;
  }
`;

const Menu = styled.div<{ isOpen: boolean }>`
  background: #fff;
  border-radius: 16px;
  pointer-events: auto;
  width: 280px;
  position: fixed;
  height: fit-content;
  opacity: 1;
  z-index: 989;
  top: 0px !important;
  right: 0px;
  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    opacity : 0;
    top: 0px !important;
  `}
  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

const BlockInfoWallet = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
// const InfoWalletConnect = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 26px;
//   height: 26px;
//   background: #fff;
//   border-radius: 8px;
//   margin: 0px 7.5px 0px 0px;
//   position: relative;
// `;
const InfoAccountConnect = styled.div`
  color: #fff;
  font-family: SUISSE_BOLD;
`;
const CheckConnect = styled.div<{ connect?: boolean }>`
  width: 16px;
  height: 16px;
  background: ${(props) => (props.connect ? "rgba(90,176,142)" : "rgba(214,83,65)")};
  border: 3px solid rgba(255, 255, 255, 0.85);
  margin: 0px 7.5px 0px 0px;
  border-radius: 50%;
`;

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  // text,
  // avatarSrc,
  // avatarClassName,
  // variant = variants.DEFAULT,
  children,
  disabled,
  placement = "bottom-end",
  recalculatePopover,
  // ellipsis = true,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes, update } = usePopper(targetRef, tooltipRef, {
    strategy: "fixed",
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
  });

  const accountEllipsis = account ? `${account.substring(0, 3)}••••${account.substring(account.length - 3)}` : null;

  // recalculate the popover position
  useEffect(() => {
    if (recalculatePopover && isOpen && update) update();
  }, [isOpen, update, recalculatePopover]);

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };

    targetRef?.addEventListener("mouseenter", showDropdownMenu);
    targetRef?.addEventListener("mouseleave", hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener("mouseenter", showDropdownMenu);
      targetRef?.removeEventListener("mouseleave", hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        onTouchStart={() => {
          setIsOpen((s) => !s);
        }}
      >
        <BlockInfoWallet>
          {/* <CheckConnect connect /> */}
          <InfoAccountConnect>{accountEllipsis}</InfoAccountConnect>
        </BlockInfoWallet>
      </StyledUserMenu>
      {!disabled && (
        <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
          <Box onClick={() => setIsOpen(true)}>{children?.({ isOpen })}</Box>
        </Menu>
      )}
    </Flex>
  );
};

export default UserMenu;
