import React from "react";
import { SvgProps } from "../types";
import Logo from "./Logo";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      className=""
    >
      <g>
        <path
          d="M12 14a3 3 0 0 0-3 3v7.026h6V17a3 3 0 0 0-3-3Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"
          className=""
        />
        <path
          d="M13.338.833a2 2 0 0 0-2.676 0L0 10.429v10.4a3.2 3.2 0 0 0 3.2 3.2H7V17a5 5 0 0 1 10 0v7.026h3.8a3.2 3.2 0 0 0 3.2-3.2v-10.4Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"
          className=""
        />
      </g>
    </svg>
  );
};

export default Icon;
