/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, memo } from "react";
import styled from "styled-components";
import isTouchDevice from "../../util/isTouchDevice";
import MenuItem from "../MenuItem/MenuItem";
import { MenuItemsProps } from "./types";

const BlockMenu = styled.div`
  position: fixed;
  height: 70px;
  z-index: 4;
  will-change: transform;
  backface-visibility: hidden;
  transition: 0.4s all;
  border-radius: 0px;
  flex-direction: row;
  padding: 0px 20px;
  width: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  backdrop-filter: blur(20px);
  border-bottom: 1.5px solid rgba(54, 105, 132, 0.45);
  @media screen and (max-width: 1199px) {
    background: rgba(27, 29, 31, 0.7);
  }
`;

const MenuItems: React.FC<React.PropsWithChildren<MenuItemsProps>> = ({ children, items = [], activeItem }) => {
  return (
    <BlockMenu>
      {items.map(({ label, items: menuItems = [], href, icon, image, disabled }) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color;
        const isActive = activeItem === href;
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href };
        const Icon = icon;
        return (
          <MenuItem
            key={`${label}#${href}`}
            {...linkProps}
            image={image}
            isActive={isActive}
            statusColor={statusColor}
            isDisabled={disabled}
          >
            <p>{label}</p>
            {icon && createElement(Icon as any, { color: isActive ? "invertedContrast" : "textSubtle" })}
          </MenuItem>
        );
      })}
      {children}
    </BlockMenu>
  );
};

export default memo(MenuItems);
