import { useState } from 'react'
import styled from 'styled-components'
import { ImageCopy } from '@pancakeswap/uikit'
import { copyText } from '../../utils/copyText'

interface CopyButtonProps {
  textCopy: string
  message?: string
}

const BtnCopy = styled.div<{ copyLoad?: boolean }>`
  width: ${(props) => (props.copyLoad ? '60px' : '28px')};
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0px 7.5px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.4s all;
  background: ${(props) => (props.copyLoad ? 'rgba(83,160,86)' : 'rgba(230, 230, 230)')};
  &:hover {
    @media screen and (max-width: 1199px) {
      background: inherit;
    }
    filter: brightness(0.9);
    transition: 0.4s all;
  }
  > div {
    color: #fff;
  }
  svg {
    width: 14px;
    height: 14px;
  }
  pointer-events: ${(props) => (props.copyLoad ? 'none' : 'auto')};
`

const IcCopy = styled(ImageCopy)`
  user-select: none;
`

const TextMessage = styled.div`
  font-family: SUISSE_BOLD;
  user-select: none;
  width: fit-content;
  color: ${({ theme }) => theme.colors.color};
`

const CopyButton: React.FC<CopyButtonProps> = ({ textCopy, message, ...props }) => {
  const [copy, setCopy] = useState(false)

  const onCopy = () => {
    setCopy(true)
    setTimeout(() => {
      setCopy(false)
    }, 1500)
  }

  return (
    <>
      <BtnCopy onClick={() => copyText(textCopy, onCopy)} {...props} copyLoad={copy}>
        {copy ? <TextMessage>{message || 'Copied'}</TextMessage> : <IcCopy />}
      </BtnCopy>
    </>
  )
}
export default CopyButton
