import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | ARB-20',
  defaultTitle: 'ARB-20',
  description: 'ARB20 - A Star is Born on Arbitrum',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@ARB-20',
    site: '@ARB-20',
  },
  openGraph: {
    title: 'ARB-20',
    description: 'ARB20 - A Star is Born on Arbitrum',
    images: [{ url: 'https://arb20.tech/logo.png' }],
  },
}
